import { NavLink, useParams } from "react-router-dom";
import Navigation from "./Navigation";
import NavigateBack from "./NavigateBack";
import { useSessionContext } from "../context/SessionContext";

interface EventHeaderProps {
  label?: string;
  to: string | number;
  showNavigateBack?: boolean;
}

const EventHeader = ({
  label = "Alle Veranstaltungen",
  to,
  showNavigateBack = true,
}: EventHeaderProps) => {
  const [session] = useSessionContext();
  const { clientName } = useParams();

  return (
    <>
      <div className="flex h-[50px] items-center gap-1 rounded-[10px] bg-primary">
        <div className="flex h-full items-center rounded-[10px] bg-primaryDark px-[30px] text-[22px] font-light max-sm:px-5 max-sm:text-base ">
          Veranstaltungen
        </div>

        {session.user && (
          <NavLink
            className={
              "text-black/35 mr-5 ml-auto overflow-hidden text-[16px] font-light hover:text-white max-sm:max-w-[200px] max-sm:text-sm"
            }
            to={`/${clientName}/profile`}
          >
            {session.user.firstname}
          </NavLink>
        )}

        {session.user && <Navigation clientName={clientName!} />}
      </div>
      {showNavigateBack && <NavigateBack label={label} to={to} />}
    </>
  );
};

export default EventHeader;
