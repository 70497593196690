import { useParams } from "react-router-dom";
import EventHeader from "../common/EventHeader";
import EventEditForm from "../event/EventEditForm";

const EventEditPage = () => {
  const { clientName } = useParams();
  return (
    <div>
      <EventHeader to={`/${clientName}`} />
      <h1 className="mb-2 text-[36px] font-light  max-lg:text-[28px] max-sm:text-[22px]">
        Neue Veranstaltung
      </h1>
      <EventEditForm />
    </div>
  );
};

export default EventEditPage;
