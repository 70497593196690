import EventListItemButtons from "./EventListItemButtons";
import { getFreeSlotsDisplay, getSlotsText } from "../../utils/helper-funcs";
import {
  EVENT_OPTION_INFINITE_SLOTS,
  Event,
} from "@code-on-the-rocks/ticket-flamingo-common";

interface EventListItemProps {
  event: Event;
  onDeleteEvent: (id: string) => void;
  onDuplicateEvent: (id: string) => void;
}

const EventListItem = (props: EventListItemProps) => {
  const { event, onDeleteEvent, onDuplicateEvent } = props;

  const {
    bookedOut,
    slotsClass,
    eventWrapperBackGroundClass,
    timeWrapperBgClass,
    slotsText,
  } = prepareStyling(event);

  return (
    <div
      key={event.id}
      className={"mb-[3px] flex rounded-[10px] " + eventWrapperBackGroundClass}
    >
      <div
        className={
          "flex w-[75px] shrink-0 flex-col items-center justify-around rounded-[10px] py-3 text-[22px] font-normal " +
          timeWrapperBgClass
        }
      >
        <span>{event.start.toHumanReadableTimeString()}</span>

        <span className="text-black/50">
          {event.end.toHumanReadableTimeString()}
        </span>
      </div>

      <div className="ml-4 flex flex-col justify-between overflow-hidden py-3 max-lg:py-2 max-sm:justify-center">
        <span className="inline-block text-[22px] font-normal max-lg:text-[20px]">
          {event.title}
        </span>
        <div className="inline-block text-[18px] font-normal text-black/70 max-lg:flex max-lg:flex-col max-lg:text-[14px] max-lg:leading-5">
          {event.descriptionShort.length > 0 && (
            <span className="max-sm:hidden">{`  ·  ${event.descriptionShort}`}</span>
          )}
          {event.location.length > 0 && <span>{`  ·  ${event.location}`}</span>}
          <span className={"font-medium " + slotsClass}>
            {`  ·  ${slotsText}`}
          </span>
        </div>
      </div>
      <div className="ml-auto flex items-center text-[22px] font-medium ">
        <EventListItemButtons
          eventId={event.id}
          bookedOut={bookedOut}
          onDeleteEvent={onDeleteEvent}
          onDuplicateEvent={onDuplicateEvent}
        />
      </div>
    </div>
  );
};

function prepareStyling(event: Event) {
  let slotsClass = " ";
  let eventWrapperBackGroundClass = "bg-primary";
  let timeWrapperBgClass = "bg-primaryDark";
  const freeSlots = getFreeSlotsDisplay(event.slots, event.slotsSold);
  const bookedOut =
    freeSlots === 0 && event.slots !== EVENT_OPTION_INFINITE_SLOTS;

  const slotsText =
    freeSlots > 0 ? getSlotsText(freeSlots) + " frei" : "Ausgebucht";

  if (event.slots === EVENT_OPTION_INFINITE_SLOTS) slotsClass = "hidden";
  else if (bookedOut) {
    eventWrapperBackGroundClass = "bg-grey";
    timeWrapperBgClass = "bg-greyDark";
  } else if (freeSlots <= 3) slotsClass = "text-red/70";

  return {
    bookedOut,
    slotsClass,
    eventWrapperBackGroundClass,
    timeWrapperBgClass,
    slotsText,
  };
}

export default EventListItem;
