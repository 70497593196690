import { useContext, useEffect, useState } from "react";
import { checkOutContext } from "../context/CheckOutContext";
import EventHeader from "../common/EventHeader";
import { TicketBuyIntent } from "../event-ticket/TicketItem";
import TicketSection from "../event-ticket/TicketSection";
import PayPalButtonWrapper, {
  OrderStatus,
  PayPalButtonWrapperItems,
} from "../common/PayPalButtonWrapper";
import { CustomerInfo } from "../event-ticket/CustomerInfoForm";
import { confirmOrder } from "../../api/eventApi";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import CheckoutMessage from "../checkout/CheckoutMessage";
import { TFCOLORS } from "../../assets/constants";
import { EVENT_OPTION_INFINITE_SLOTS } from "@code-on-the-rocks/ticket-flamingo-common";
import { useScrollParentToTop } from "../hooks/useScrollParentToTop";
import {
  ReactPayPalScriptOptions,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { getPaypalClientIdByNameCached } from "../../api/clientApi";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../common/LoadingIndicator";

export interface CheckOutData {
  eventId: string;
  eventTitle: string;
  tickets: TicketBuyIntent[];
  customerInfo: CustomerInfo;
}

enum CHECKOUT_STATE {
  OPEN,
  APPROVED,
  ERROR,
}

export const initialPaypalButtonOptions: Omit<
  ReactPayPalScriptOptions,
  "client"
> = {
  currency: "EUR",
  intent: "capture",
  //"data-client-token": "abc123xyz==",
  "integration-date": "2024-06-06",
};

const CheckOutPage = () => {
  const [
    checkoutState,
    eventId,
    eventTitle,
    tickets,
    customerInfo,
    handleError,
    handleValidate,
    handleApprove,
  ] = useCheckoutLogic();

  const paypalPurchaseItems: PayPalButtonWrapperItems[] = tickets.map(
    (ticket) => {
      return {
        name: ticket.name,
        quantity: ticket.amount,
        price: ticket.price,
      };
    }
  );

  const renderTickets = !(
    eventTitle === "" ||
    tickets.length === 0 ||
    !customerInfo
  );

  return (
    <div>
      <EventHeader label="Zurück zur Veranstaltung" to={-1} />

      {checkoutState === CHECKOUT_STATE.APPROVED && (
        <div className="mx-5 mt-5 mb-12">
          <CheckoutMessage
            icon={faCheck}
            title="Vielen dank für deinen Einkauf!"
            subtitle="Deine Tickets erhältst du via E-Mail."
            iconColor={TFCOLORS.success}
          />
        </div>
      )}

      {checkoutState === CHECKOUT_STATE.ERROR && (
        <div className="mx-5 mt-5 mb-12">
          <CheckoutMessage
            icon={faX}
            title="Oops, es ist was schief gegangen!"
            subtitle="Bitte versuche es erneut oder schreibe uns eine E-Mail."
            iconColor={TFCOLORS.error}
          />
        </div>
      )}

      {renderTickets && (
        <TicketSection
          creationMode={false}
          headline={eventTitle}
          tickets={tickets}
          slots={EVENT_OPTION_INFINITE_SLOTS}
          slotsSold={EVENT_OPTION_INFINITE_SLOTS}
          amountChangeable={false}
        >
          <div className="flex max-lg:flex-col max-lg:gap-3">
            <div className="flex flex-1 flex-col">
              <div className="flex gap-1">
                <span>Name:</span>
                <span>{customerInfo.name}</span>
              </div>
              <div className="flex gap-1">
                <span>E-Mail:</span>
                <span>{customerInfo.email}</span>
              </div>
            </div>
            {checkoutState !== CHECKOUT_STATE.APPROVED && (
              <div className="max-lg:mr-auto">
                <PayPalButtonWrapper
                  currency_code="EUR"
                  items={paypalPurchaseItems}
                  onValidate={(orderId: string) =>
                    handleValidate(eventId, tickets, orderId)
                  }
                  onCancel={handleCancel}
                  onError={handleError}
                  onApprove={(orderId: string, orderStatus: OrderStatus) =>
                    handleApprove(eventId, tickets, orderId)
                  }
                  LoadingIndicator={<LoadingIndicator show={true} />}
                />
              </div>
            )}
          </div>
        </TicketSection>
      )}

      {checkoutState === CHECKOUT_STATE.OPEN && !renderTickets && (
        <div className="m-5 text-lg max-sm:text-base">
          Oops, es wurden keine Tickets ausgewählt!
        </div>
      )}
    </div>
  );
};

function useCheckoutLogic(): [
  CHECKOUT_STATE,
  string,
  string,
  TicketBuyIntent[],
  CustomerInfo | null,
  (error: Record<string, unknown>) => void,
  (
    eventId: string,
    tickets: TicketBuyIntent[],
    orderId: string
  ) => Promise<boolean>,
  (eventId: string, tickets: TicketBuyIntent[], orderId: string) => void
] {
  const { clientName } = useParams();
  const [checkoutState, setCheckoutState] = useState(CHECKOUT_STATE.OPEN);
  const { checkOutData } = useContext(checkOutContext)!;
  const { eventId, eventTitle, tickets, customerInfo } = checkOutData;

  const [, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    const resetPaypalProviderOptions = async () => {
      try {
        const clientId = await getPaypalClientIdByNameCached(clientName!);
        const paypalButtonOptions: ReactPayPalScriptOptions = {
          clientId: clientId,
          ...initialPaypalButtonOptions,
        };
        dispatch({ type: "resetOptions", value: paypalButtonOptions });
      } catch (error) {
        console.error("Failed to fetch PayPal client ID", error);
      }
    };

    resetPaypalProviderOptions();
  }, [clientName, dispatch]);

  useScrollParentToTop();

  const handleError = (error: Record<string, unknown>) => {
    setCheckoutState(CHECKOUT_STATE.ERROR);
    console.log("CHECKOUT | HANDLE ERROR: ", error);
  };

  const handleValidate = async (
    eventId: string,
    tickets: TicketBuyIntent[],
    orderId: string
  ) => {
    try {
      console.log("HANDLE VALIDATE START");
      const response = await confirmOrder(true, eventId, orderId, tickets, {
        email: customerInfo.email,
        name: customerInfo.name,
      });

      console.log("HANDLE VALIDATE DONE");
      if (response.status === 200) {
        return true;
      }

      setCheckoutState(CHECKOUT_STATE.ERROR);
      return false;
    } catch (error) {
      setCheckoutState(CHECKOUT_STATE.ERROR);
      console.log("HANDLE VALIDATE FAILED: ", error);
      return false;
    }
  };

  const handleApprove = async (
    eventId: string,
    tickets: TicketBuyIntent[],
    orderId: string
  ) => {
    try {
      console.log("HANDLE APPROVE START");
      const response = await confirmOrder(false, eventId, orderId, tickets, {
        email: customerInfo.email,
        name: customerInfo.name,
      });

      if (response.status !== 200)
        throw new Error(response.status + " - " + response.statusText);

      setCheckoutState(CHECKOUT_STATE.APPROVED);
    } catch (error) {
      setCheckoutState(CHECKOUT_STATE.ERROR);
      console.log("HANDLE APPROVE FAILED: ", error);
    }
  };

  return [
    checkoutState,
    eventId,
    eventTitle,
    tickets,
    customerInfo,
    handleError,
    handleValidate,
    handleApprove,
  ];
}

const handleCancel = (data: Record<string, unknown>) => {
  // transaction has been canceled
  // just stay at checkout page
};

export default CheckOutPage;
